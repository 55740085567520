import React from "react"
import Login from "./Login"
import Loading from "./Loading"
import { useAuth0 } from "@auth0/auth0-react"
import { Link } from "gatsby"

const Homepage = () => {
  const { isAuthenticated, isLoading } = useAuth0()

  if (isLoading) {
    return <Loading />
  }

  return (
    <section className="section">
      {isAuthenticated ? (
        <section className="homepage">
          <Link to="/home/" className="link btn home">
            Home
          </Link>
          {/* <Link to="/condo/" className="link btn condo">
            Condo
          </Link>
          <Link to="/landlord/" className="link btn landlord">
            Landlord
          </Link>
          <Link to="/renters/" className="link btn renters">
            Renters
          </Link> */}
          <Link to="/auto/" className="link btn auto">
            Auto
          </Link>
          {/* <Link to="/motorcycle/" className="link btn motorcycle">
            Motorcycle
          </Link>
          <Link to="/boat/" className="link btn boat">
            Boat
          </Link>
          <Link to="/orv/" className="link btn orv">
            ORV
          </Link>
          <Link to="/umbrella/" className="link btn umbrella">
            Umbrella
          </Link> */}
        </section>
      ) : (
        <Login />
      )}
    </section>
  )
}

export default Homepage
